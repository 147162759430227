import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {

    location: any;
    navbarClass: any;
    logo: any;
    navContainer: any;

    constructor(
        private router: Router,
        location: Location
    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/'){
                    this.navbarClass = 'navbar-area';
                    this.navContainer = 'container';
                    this.logo = 'assets/img/logo.png';
                } else if (this.location == '/home-2'){
                    this.navbarClass = 'navbar-area navbar-style-two';
                    this.navContainer = 'container-fluid';
                    this.logo = 'assets/img/white-logo.png';
                } else if (this.location == '/home-3'){
                    this.navbarClass = 'navbar-area navbar-style-two';
                    this.navContainer = 'container-fluid';
                    this.logo = 'assets/img/white-logo.png';
                } else {
                    this.navbarClass = 'navbar-area';
                    this.navContainer = 'container';
                    this.logo = 'assets/img/logo.png';
                }
            }
        });
    }

    ngOnInit() {
    }

}
