import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ThePlaceComponent } from './components/pages/the-place/the-place.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { EventComponent } from './components/pages/event/event.component';
import { EventDetailsComponent } from './components/pages/event-details/event-details.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { TeamComponent } from './components/pages/team/team.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    // {path: 'home-2', component: HomeTwoComponent},
    // {path: 'home-3', component: HomeThreeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'our-solutions', component: ThePlaceComponent},
    // {path: 'event', component: EventComponent},
    // {path: 'event-details', component: EventDetailsComponent},
    // {path: 'gallery', component: GalleryComponent},
    // {path: 'services', component: ServicesComponent},
    // {path: 'services-details', component: ServicesDetailsComponent},
    // {path: 'pricing', component: PricingComponent},
    // {path: 'team', component: TeamComponent},
    {path: 'error', component: ErrorComponent},
    // {path: 'blog', component: BlogComponent},
    // {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'contact', component: ContactComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }